.app__Menusetting {
  color: black;
  height: 100%;
  min-height: 760px;
  background-color: #efeef0;
}

.app__Menusetting-scroll {
  height: 100%;
  width: 100%;
  /* margin-left: 340px; */
  margin-top: 110px;
  min-height: 760px;
  max-height: max-content;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgb(255, 253, 253);
  border-radius: 12px;
}

.menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 82%;
  height: 400px;
  /* margin: 30px; */
  margin-top: 110px;
  /* margin-left: 333px; */
  border-radius: 15px;
  background: linear-gradient(
    135deg,
    rgba(0, 7, 61, 1) 0%,
    rgba(29, 39, 95, 1) 30%,
    rgba(69, 114, 172, 1) 65%,
    rgba(156, 197, 230, 1) 100%
  );
  box-shadow: 0 25px 75px rgba(16, 30, 54, 0.25);
}

.menu-container-heading {
  font-size: 1.6rem;
  font-weight: 500;
  color: #e6e8f1;
  text-align: center;
  letter-spacing: 0.05rem;
  margin: 0;
  padding: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.menu-setting-content {
  display: flex;
  font-size: 30px;
  justify-content: space-between;
  font-weight: 600;
  margin: 30px 30px 0px 30px;
}

.app__Menusetting-scroll::-webkit-scrollbar {
  display: none;
}

.app__Menusetting-swaper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid rgb(78, 3, 15); */
  /* background-color: #f9f5fa; */
}

.app__Menusetting-setting {
  margin: 3rem;
  /* display: flex; */
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
}

.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 59px;
  border-radius: 14px;
  background: #00073d;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bolder;
  border: 1px solid rgba(0, 7, 61, 1);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin: 3px;
  padding: 2px 0;
}
.secondary-button-ai {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 48px;
  border-radius: 14px;
  background: #00073d;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bolder;
  border: 1px solid rgba(0, 7, 61, 1);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin: 3px;
  padding: 2px 0;
}

.secondary-button:hover {
  box-shadow: none;
}
.secondary-button-ai:hover {
  box-shadow: none;
}
@media (max-width: 768px) {
  .app__Menusetting-scroll {
    /* margin-left: 80px; */
    margin-top: 31px;
  }
  .app__Menusetting-scroll::-webkit-scrollbar {
    display: none;
  }
  .secondary-button {
    width: 168px;
    height: 49px;
    font-size: 16px;
  }
}

@media (max-width: 1150px) {
  .app__Menusetting-scroll {
    /* margin-left: 115px; */
    margin-top: 30px;
  }
  .app__Menusetting-scroll::-webkit-scrollbar {
    display: none;
  }
  .secondary-button {
    width: 143px;
    height: 49px;
    font-size: 16px;
  }
}
