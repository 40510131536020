.app__Menu1-Edited {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.flex-coulmn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app__Menu1-Edited-itemA {
  background: #f5f0f0;
  box-shadow: 0 25px 75px rgba(235, 236, 238, 0.507);
  width: 100%;
  padding: 30px 50px 50px 50px;
}

.flexc {
  display: flex;
  justify-content: space-between;
}

.app__Menu1-Edited-itemA-img {
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: baseline;
  padding-bottom: 30px;
  padding-top: 30px;
}

.app__checkout5-card-Edited_img
  .lazy-load-image-background.blur.lazy-load-image-loaded {
  width: 100%;
  height: 100%;
}

.product-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 10px 0px 10px; */
  margin-top: 12px;
  margin-right: 5px;
  /* padding: 2px; */
}

.app__Menu1-Edited-itemA-img .img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}

.app__Menu1-Edited-itemA-img .img::before {
  content: "";
  width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 50%;
}

.app__Menu1-Edited-itemA-title {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
  color: #030303;
  padding-left: 30px;
}

.app__Menu1-Edited-itemA-items {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.app__Menu1-Edited-itemA-items_item {
  width: 90%;
  height: 120px;
  display: flex;
  flex-direction: row;
  margin-left: 1.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0px;
  background: #030303;
  box-shadow: 0 25px 75px rgba(226, 227, 228, 0.25);
  background: transparent;
  cursor: pointer;
}

.app__Menu1-Edited-itemA-items_item-img {
  height: 120px;
  transform: translateX(-1.5rem);
  display: flex;
  align-items: center;
  position: relative;
}

.app__Menu1-Edited-itemA-items_item-img .img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}

.app__Menu1-Edited-itemA-items_item-img .img::before {
  content: "";
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 50%;
}

.app__Menu1-Edited-itemA-items_item-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.app__Menu1-Edited-itemA-items_item-name {
  display: flex;
  align-items: center;
  color: rgb(5, 5, 5);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.app__Menu1-Edited-itemA-items_item-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}

.app__Menu1-Edited-itemB {
  flex: 1;
  background: green;
  width: 100%;
  margin: 2rem;
}

.app__Menu1-Edited-itemA_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app__checkout5-card-Edited {
  width: 194px;
  /* height: 282px; */
  height: fit-content;
  background: #ffffff;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  border: 1px solid rgba(244, 245, 247, 0.712);
  border-radius: 20px;
  padding: 30px;
  margin: 30px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.205);
}

.app__checkout5-card-Edited-info {
  /* margin-left: 1rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: baseline; */
  align-items: center;
}

.app__checkout5-card-Edited-name {
  width: 100%;
  font-weight: 600;
  color: black;
  margin-top: 10px;
  /* padding: 10px; */
  height: fit-content;
  text-align: center;
}

.app__checkout5-card-Edited-buttons {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-back-icon {
  /* border: 1px solid black; */
  border-radius: 50px;
  background-color: #eee;
  cursor: pointer;
}

.app__checkout5-card-Delete-buttons {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: inline;
}

.app__checkout5-card-Edited-buttons button {
  width: 50px;
  height: 30px;
  cursor: pointer;
  background-color: rgb(3, 201, 250);
}

.app__checkout5-card-Edited-price {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin-top: 10px;
  /* display: inline; */
}

.app__checkout5-card-Edited_img {
  width: 100px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__checkout5-card-Edited_img .img {
  width: 80px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width: 1500px) {
  .app__Menu1-Edited-itemA {
    width: 47%;
  }
}

@media screen and (max-width: 1210px) {
  .app__Menu1-Edited-itemA {
    width: 100%;
  }
}

.app__Editedorderdetail-popup {
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.app__Editedorderdetail-popup_inner {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7),
    rgba(2, 9, 49, 0.712)
  );
  backdrop-filter: blur(5px);
  z-index: 7;
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app__Editedorderdetail-popup_inner::-webkit-scrollbar {
  display: none;
}

.app__Editedorderdetail-popup_inner .app__Editedorderdetail-popup_closeicon {
  border: 1px solid black;
}

.hello {
  color: black;
}
