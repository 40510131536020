.swiper-start {
  display: flex;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.swap-component {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: white;
}

.product-popup {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
  border-radius: 12px;
  background-color: white;
}

.swiper-content {
  display: flex;
  /* justify-content: space-between; */
}

.swiper-card {
  margin: 2rem;
}

.swiper {
  color: black;
}

.swiper-img {
  height: 300px;

  width: 100%;
  display: flex;
  justify-content: center;
}

.swiper-img img {
  height: 280px;
  width: 360px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.2);
}

.swiper-name {
  font-size: larger;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  width: 75%;
  border-radius: 12px;
  margin: 0 15px 0 15px;
}

@media screen and (max-width: 1150px) {
  .swap-component {
    width: 80%;
    height: 100%;
    margin-left: 20px;
    margin-top: 80px;
    border-radius: 12px;
    background-color: white;
  }
}
