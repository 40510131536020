.App__Box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-input-box {
  height: 190px;
  width: 400px;
  border-radius: 13px;
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s;
  font-family: Arial, Helvetica, sans-serif;
  padding: 30px;
  color: #aaaaaa;
  margin: 4px 0;
}

.file-upload-container {
  position: absolute;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.custom-dropdown-button {
  width: 350px;
  padding: 1.3rem 1rem;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.custom-dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.custom-dropdown-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.custom-dropdown-option:hover {
  background-color: #f0f0f0;
}

.custom-dropdown-option img {
  margin-right: 10px;
}

.image-label {
  font-size: 14px;
  color: #333;
}

.predefined-images-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
}

.selected-image-preview img {
  display: block;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.primary-input-box-button {
  height: 50px;
  width: 100px;
  cursor: pointer;
  color: #aaaaaa;
}

.primary-select-box {
  display: flex;
  width: 400px;
  flex-direction: column;
  margin-top: 2.1rem;
  font-family: "Arial", sans-serif;
}

.primary-select-box .primary-select-box-options-container {
  background: rgb(238, 239, 241);
  color: #121213;
  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  overflow: hidden;
  z-index: 15;
  order: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.primary-select-box-selected {
  background: rgb(0, 7, 61);
  border-radius: 8px;
  margin-bottom: 8px;
  color: #f5f6fa;
  position: relative;
  order: 0;
  width: 100%;
  box-shadow: 0 8px 18px rgba(123, 104, 238, 0.3),
    0 4px 6px rgba(123, 104, 238, 0.2);
  font-size: 1.1rem;
  transition: all 0.3s ease;
  font-family: Arial, Helvetica, sans-serif;
}

.primary-select-box-selected::after {
  content: "";
  background: url("../../../../../../../imges/down-arrow-multimedia-option-svgrepo-com.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 36px;
  right: 10px;
  top: 0;
  transition: all 0.4s;
}

.primary-select-box .primary-select-box-options-container.active {
  max-height: 150px;
  opacity: 1;
  overflow-y: scroll;
  z-index: 15;
}

.primary-select-box
  .primary-select-box-options-container.active
  + .primary-select-box-selected::after {
  transform: rotateX(180deg);
}

.primary-select-box .primary-select-box-options-container::-webkit-scrollbar {
  width: 10px;
  background: #5e6166;
  border-radius: 12px;
  border: none;
}

.primary-select-box
  .primary-select-box-options-container::-webkit-scrollbar-thumb {
  background: #fbfcfd;
  border: none;
}

.primary-select-box .primary-select-box-option-input {
  width: 100%;
  padding: 12px 24px;
  cursor: pointer;
  color: #ffff;
  border: 1px solid white;
}

.primary-select-box .primary-select-box-option,
.primary-select-box-selected {
  padding: 12px 24px;
  cursor: pointer;
}

.primary-select-box .primary-select-box-option:hover {
  background: #414b57;
}

.primary-select-box label {
  cursor: pointer;
}

.primary-select-box .primary-select-box-option .primary-select-box-radio {
  display: none;
}
.vector-svg {
  font-size: 1.2rem;
  width: 40px;
  height: 27px;
  /* margin-bottom: 0.5rem; */
  margin-left: 33px;
  margin-top: 17px;
}
.photo-p {
  font-size: 12px;
  color: #f70404;
  padding: 6px 0;
  text-align: center;
}
.mark {
  color: #aaaaaa;
}
@media screen and (max-width: 540px) {
  .app__createmenu-product-form {
    width: 380px;
  }
}

@media screen and (max-width: 400px) {
  .app__createmenu-product-form {
    width: 280px;
  }
}

/* signup css */

.app__signup-page {
  position: relative;
}

.app__signup-page_form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: absolute;
  margin: 50px;
}

.app__signup-page_form-content-form {
  padding: 1rem 3rem;
}

.app__signup-page_form-content {
  width: 500px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
}

.app__signup-page_form-content-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 550;
  line-height: 23px;
  color: #292d34;
  text-align: center;
  margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.app__signup-page_form-content-label-m {
  font-size: 1.4rem;
  margin: 0.4rem;
  padding: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.app__signup-page_form-content-label-red-m {
  margin-top: 0.3rem;
  color: #f70404;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.app__signup-page_form-content-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border: 1px solid #292d34;
  border-radius: 5px;
  font-family: var(--font-base);
  font-size: 1rem;
  color: var(--color-black);
  padding: 0.75rem 1rem;
  background: var(--color-white);
}

.app__signup-page_form-content-input_Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border: 1px solid #fa0101;
  border-radius: 5px;
  font-family: var(--font-base);
  font-size: 1rem;
  color: var(--color-black);
  padding: 0.75rem 1rem;
  background: var(--color-white);
}

.app__signup-page_form-content-input-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border: 1px solid #aaaaaa;
  border-radius: 15px;
  font-family: var(--font-base);
  font-size: 1rem;
  color: var(--color-black);
  padding: 1.3rem 1rem;
  background: var(--color-white);
}

.app__signup-page_form-content-input-name_Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border: 1px solid #f70404;
  border-radius: 5px;
  font-family: var(--font-base);
  font-size: 1rem;
  color: var(--color-black);
  padding: 0.75rem 1rem;
  background: var(--color-white);
}

.app__signup-page_form-content-input-name-input {
  margin-left: 10px;
  font-size: 1.1rem;
}

.app__signup-page_form-content-input input {
  border: none;
  margin-left: 10px;
  outline: #ffffff;
}

.app__signup-page_form-content-input_Red input {
  border: none;
  margin-left: 10px;
  outline: #ffffff;
}

.app__signup-page_form-content-input-forgetpassword {
  margin-left: 100px;
  display: flex;
  align-items: center;
}

.app__signup-page_form-content-input-forgetpassword-link {
  width: 25px;
  height: 25px;
}

.app__signup-page_form-content-input-name input {
  border: none;
}

.app__signup-page_form-content-input-name-input {
  outline: rgb(250, 249, 249);
}

.app__signup-page_form-content-input-name_Red input {
  outline: rgb(250, 249, 249);
  border: none;
}

.primary-button {
  position: relative;
  width: 100%;
}

.primary-button button {
  background-color: rgb(0, 7, 61);
  width: 100%;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 15px;
  font-size: 1.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin-bottom: 10px;
}

.app__signup-page_form-content-input-name-hidetag {
  display: flex;
  flex-direction: row;
  width: 280px;
  color: #f70404;
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.app__signup-page_form-content-input-name-hide {
  color: #ffffff !important;
}

.app__signup-Icons {
  height: 20px;
  width: 20px;
}

.app__signup-Icons-red {
  height: 20px;
  width: 20px;
  color: #fa0101;
}

.app__signup-page_form-content-conditions {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #7c828d;
  background-color: #fafbfc;
  border-top: 1px solid #e9ebf0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.app__signup-page_form-content-input-text {
  color: blue;
  margin-top: 20px;
}

.PhoneInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border: 1px solid #292d34;
  border-radius: 5px;
  font-family: var(--font-base);
  font-size: 1rem;
  color: #292d34 !important;
  padding: 0.3rem 0.3rem;
  background: var(--color-white);
  border: none;
  outline: rgb(250, 249, 249) !important;
}

.PhoneInputCountry {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PhoneInputCountrySelect {
  width: 20px;
  height: 20px;
  margin: 0.2rem;
  outline: rgb(250, 249, 249);
  border: none;
  background: var(--color-white);
}

.PhoneInputCountryIcon {
  width: 70px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
}

.PhoneInputInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border: 1px solid #292d34;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bolder;
  color: var(--color-black);
  padding: 0.3rem 0.3rem;
  background: var(--color-white);
  outline: rgb(250, 249, 249);
  border: none;
}

.app__login-page_form-content-input {
  border: 1px solid black;
}

@media screen and (max-width: 1000px) {
  .app__signup-page_form-content-input-forgetpassword {
    margin-left: 90px;
  }
}

@media screen and (max-width: 950px) {
  .app__signup-page_form-content {
    width: 500px;
  }

  .app__signup-page_form-content-input-forgetpassword {
    margin-left: 80px;
  }
}

@media screen and (max-width: 650px) {
  .app__signup-page_form-content {
    width: 400px;
  }

  .app__signup-page_form-content-input-forgetpassword {
    margin-left: 20px;
  }

  .app__signup-page_form-content-input {
    padding: 0.1rem 0.3rem;
  }

  .app__signup-page_form-content-input_Red {
    padding: 0.1rem 0.3rem;
  }

  .app__signup-Icons {
    height: 25px;
    width: 15px;
  }

  .app__signup-Icons-red {
    height: 25px;
    width: 15px;
  }

  .app__signup {
    background-size: cover;
  }
}

@media screen and (max-width: 400px) {
  .app__signup-page_form-content {
    width: 300px;
  }

  .app__signup-page_form-content-form {
    padding: 0.5rem 1rem;
  }

  .app__signup-page_form-content-input-forgetpassword {
    margin-left: 10px;
  }

  .app__signup-page_form-content-conditions {
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
  }
}

@media screen and (max-width: 300px) {
  .app__signup-page_form-content {
    width: 250px;
  }

  .app__signup-page_form-content-form {
    padding: 0.1rem 0.3rem;
  }

  .app__signup-page_form-content-input-forgetpassword {
    margin-left: 0px;
  }

  .app__signup-page_form-content-conditions {
    font-size: 7px;
  }
}
