@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");

:root {
  --font-base: "Cormorant Upright", serif;
  --font-alt: "Open Sans", sans-serif;

  --color-golden: #dcca87;
  --color-black: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(3, 2, 53, 1) 7%,
    rgba(6, 6, 92, 1) 23%,
    rgba(9, 9, 121, 1) 35%,
    rgba(8, 37, 140, 1) 44%,
    rgba(6, 75, 165, 1) 56%,
    rgba(4, 115, 192, 1) 69%,
    rgba(2, 159, 220, 1) 83%,
    rgba(1, 184, 236, 1) 91%,
    rgba(0, 212, 255, 1) 100%
  );
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-left {
  -webkit-animation: slide-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slidesidebar-left {
  -webkit-animation: slidesidebar-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slidesidebar-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slidesidebar-left {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slidesidebar-left {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
