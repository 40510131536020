.app__signup-page_form-twoform {
  display: flex;
}

.App__Box {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.App__InputBox {
  height: 300px;
  width: 400px;
  border: 1px dotted black;

  background: rgb(245, 237, 237);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.App__Box-img {
  position: absolute;
}

.App__InputBox-button {
  height: 50px;
  width: 100px;
  cursor: pointer;
  /* background-color: aqua; */
}

.mark {
  color: #aaaaaa;
}
.photo-p {
  color: #f70404;
  padding-top: 6px 0;
}
