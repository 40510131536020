.loader-size {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000000;
}

.loader-size-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-size-container .loader-size-container-ring {
  position: relative;
  width: 150px;
  height: 150px;
  margin: -30px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #24ecff;
  animation: animate 4s linear infinite;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-size-container .loader-size-container-ring::before {
  content: "";
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #24ecff;
  box-shadow: 0 0 0 5px #24ecff44, 0 0 0 10px #24ecff33, 0 0 0 20px #24ecff22,
    0 0 0 25px #24ecff11, 0 0 0 30px #24ecff11;
}
.loader-size-container .loader-size-container-ring:nth-child(2) {
  animation: animate2 4s linear infinite;
  animation-delay: -1s;
  border-top: 4px solid transparent;
  border-left: 4px solid #93ff2d;
}
.loader-size-container .loader-size-container-ring:nth-child(2)::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: 12px;
  left: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #93ff2d;
  box-shadow: 0 0 0 5px #93ff2d44, 0 0 0 10px #93ff2d33, 0 0 0 20px #93ff2d22,
    0 0 0 25px #93ff2d11, 0 0 0 30px #93ff2d11;
}
@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.loader-size-container .loader-size-container-ring:nth-child(3) {
  animation: animate2 4s linear infinite;
  animation-delay: -3s;
  position: absolute;
  top: -66.66px;
  border-top: 4px solid transparent;
  border-left: 4px solid #e41cf8;
}
.loader-size-container .loader-size-container-ring:nth-child(3)::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: 12px;
  left: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #e41cf8;
  box-shadow: 0 0 0 5px #e41cf844, 0 0 0 10px #e41cf833, 0 0 0 20px #e41cf822,
    0 0 0 25px #e41cf811, 0 0 0 30px #e41cf811;
}
.loader-size-container p {
  position: absolute;
  color: #ffff;
  font-size: 1.5em;
  font-family: "Courier New";
  /* bottom:-80px; */
  letter-spacing: 0.15em;
  color: #111;
}
